import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import L from 'leaflet';
import styled from 'styled-components';
import axios from 'axios';
import { BASE_URL_API } from "../environment";
import { Spinner } from 'react-bootstrap';
import {useParams} from 'react-router-dom';


// Fix for missing marker icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png',
});

// Define the gradient color function
const getColor = (index, total) => {
  const startColor = [255, 0, 0]; // Red
  const endColor = [0, 255, 0];   // Green
  const factor = index / total;
  const color = startColor.map((start, i) => Math.round(start + factor * (endColor[i] - start)));
  return `rgb(${color.join(',')})`;
};

// Create gradient icons
const createIcon = (color) => new L.Icon({
  iconUrl: `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="${color}" d="M16 0C9.383 0 4 5.383 4 12c0 8.57 10.015 19.029 10.361 19.383a1 1 0 0 0 1.278 0C18 31 28 20.57 28 12 28 5.383 22.617 0 16 0zm0 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/></svg>`,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// Custom car icon for the latest location
const carIcon = new L.Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png', // Blue icon
  iconRetinaUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png', // Blue icon
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const Card = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.div`
  background-color: #f0f0f0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardBody = styled.div`
  height: 800px; /* Adjust height as needed */
`;

const Title = styled.h1`
  margin: 0;
`;

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-right: 10px;
`;

const Button = styled.button`
  margin-left: 10px;
`;

const MapWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const TooltipContent = styled.div`
  font-size: 14px;
  color: #fff;  /* Set text color to white for better contrast */
  padding: 5px;
  border-radius: 5px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AgentTracking = () => {
  const { agentId, date } = useParams();
console.log("the agentId is",agentId);
console.log("the date is",date);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const fetchTrackingData = async () => {
      if (agentId && date) {
        const formattedDate = date.split('-').reverse().join('-');
        setSelectedUser(agentId);
        setSelectedDate(formattedDate);
        try {
          // const formattedDate = date.split('-').reverse().join('-');
          const apiUrl = `${BASE_URL_API}trackAgent/${agentId}/${date}`;
          const response = await fetch(apiUrl);
          const result = await response.json();

          if (result.isSuccess && result.data.length > 0) {
            const locationData = result.data[0].location.filter(
              (loc) => loc.latitude && loc.longitude
            );
            setLocations(locationData);
          } else {
            setLocations([]);
            console.warn('No tracking data found for user on selected date');
          }
        } catch (error) {
          console.error('Error fetching location data:', error);
          setLocations([]);
        }
      }
    };
    const fetchUsers = async () => {
      try {
        const response = await fetch(BASE_URL_API + "users/allApprovedUsers");
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
    fetchTrackingData();
  }, [agentId,date]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const formattedDate = selectedDate.split('-').reverse().join('-');
      const apiUrl = `${BASE_URL_API}trackAgent/${selectedUser}/${formattedDate}`;
      const response = await fetch(apiUrl);
      const result = await response.json();

      if (result.isSuccess && result.data.length > 0) {
        const locationData = result.data[0].location.filter(loc => loc.latitude && loc.longitude);
        setLocations(locationData);
      } else {
        setLocations([]);
        console.warn('No tracking data found for user on selected date');
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
      setLocations([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>
        <Title>Agent Tracking</Title>
        <ControlsWrapper>
          <Label>
            Select User:
            <select className="form-select form-select-md" value={selectedUser} onChange={handleUserChange}>
              <option value="">Select User</option>
              {users.map(user => (
                <option key={user._id} value={user._id}>{user.name}</option>
              ))}
            </select>
          </Label>
          <Label>
            Select Date:
            <input type="date" className="form-control" value={selectedDate} onChange={handleDateChange} />
          </Label>
          <Button className="btn btn-primary mt-3" onClick={handleSearch}>Search</Button>
        </ControlsWrapper>
      </CardHeader>
      <CardBody>
        {loading ? (
          <LoaderWrapper>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </LoaderWrapper>
        ) : (
          <MapWrapper>
            <MapContainer center={[20.2964992, 85.835776]} zoom={13} style={{ height: '100%', width: '100%' }}>
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Normal View">
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite View">
                  <TileLayer
                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=YOUR_MAPBOX_ACCESS_TOKEN"
                    attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              {locations.map((location, index) => {
                const isLatest = index === locations.length - 1;
                const color = getColor(index, locations.length - 1);
                const iconColor = isLatest ? carIcon : createIcon(color);
                return (
                  <Marker 
                    key={index} 
                    position={[location.latitude, location.longitude]}
                    icon={iconColor}
                  >
                    <Tooltip direction="top" offset={[0, -10]} opacity={1} permanent>
                      <TooltipContent style={{ backgroundColor: color }}>
                        <strong>Point {String.fromCharCode(65 + index)}</strong><br />
                        Time: {location.time || 'N/A'}<br />
                      </TooltipContent>
                    </Tooltip>
                  </Marker>
                );
              })}
            </MapContainer>
          </MapWrapper>
        )}
      </CardBody>
    </Card>
  );
};

export default AgentTracking;
