import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL_API } from "../environment";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, Form, Row, Col, Button, Spinner } from 'react-bootstrap';

function OrderSettlement() {
    var adm = localStorage.getItem('token');
    const [orderData, setOrderData] = useState([]);
    const navigate = useNavigate();
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      // today date in dd-mm-yyyy format.
      const getFormattedDate = (dateString) => {
        if (!dateString) {
          console.error("Date string is null or empty.");
          return dateString;
        }
        
        let date;
        if (dateString instanceof Date) {
          date = dateString;
        } else {
          const dateParts = dateString.split("-");
          if (dateParts.length === 3) {
            if (dateParts[0].length === 4) {
              date = new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);
            } else {
              date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
            }
          } else {
            console.error("Invalid date format:", dateString);
            return dateString;
          }
        }
      
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      
      // date in dd-mm-yyyy format.
      const formatDateddmmyy = (dateString) => {
        const parts = dateString.split("-");
        if (parts.length === 3) {
          const day = parts[2];
          const month = parts[1];
          const year = parts[0];
          return `${day}-${month}-${year}`;
        }
        return dateString;
      };
    
      // after 7 date in dd-mm-yyyy format.
      const getDateAfterDays = (days) => {
        if (!days) {
          console.error("Date string is null or empty.");
          return days;
        }
    
        const today = new Date();
        const dateAfterDays = new Date(today.setDate(today.getDate() + days));
        return getFormattedDate(dateAfterDays); // Corrected line
      };
    //   getFormattedDate(getDateAfterDays(6))
    //   getFormattedDate(new Date())
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [cityid, setCityId] = useState(null);
    const [selectcityid, setSelectcityid] = useState("");
    const [totaloffset, setTotalOffset] = useState(null);
    const [offset, setOffset] = useState("");
    const [orderId, setOrderId] = useState("");
    const [customername, setCustomerName] = useState("");
    const [statusFilter, setStatusFilter] = useState("Pickup Completed");
    const [scdate, setScdate] = useState("");
    const [mobileno, setMobileNo] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [agentname, setAgentName] = useState("");
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false); 
    const [sattle, setSattle] = useState(false); 
    const [mode, setMode] = useState("");
    const [transctionid, setTransactionId] = useState("");
    const [settledate, setSettleDate] = useState("");
    const [notes, setNotes] = useState(""); 
    const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
    }, [adm, fromDate, toDate, selectcityid, offset, statusFilter, scdate, orderId, customername, organisation, mobileno, agentname]);

    const fetchData = () => {
    axios
        .get(BASE_URL_API + "orders/adminhistorder/" + adm+"?startDate="+formatDateddmmyy(fromDate)+"&endDate="+formatDateddmmyy(toDate)+"&offset="+offset+"&cityName="+selectcityid+"&status="+statusFilter+"&scheduledate="+formatDateddmmyy(scdate)+"&orderId="+orderId+"&customerName="+customername+"&mobileNumber="+mobileno+"&organisation="+organisation+"&agent="+agentname)
        .then((res) => {
        console.log("total count data is"+Math.ceil(res.data.count));
        const totalOffset = Math.ceil(res.data.count/ 10);
        setTotalOffset(totalOffset);
        console.log("totaloffsetis"+totalOffset);
        setTotalItems(res.data.count);
        setOrderData(res.data.data);        
        })
        .catch((error) => {
        console.error("Error fetching data:", error);
        setOrderData([]);
        });

        axios
        .get(BASE_URL_API + "items/allCityPercent")
        .then((res) => setCityId(res.data))
        .catch((err) => console.log(err));
    };
    const handleSelectAll = () => {
        if (isAllSelected) {
          setSelectedOrders([]);
        } else {
          const ordersToSelect = orderData
            .filter(data => data.status === 'Pickup Completed')
            .map(data => data.order_id);
          setSelectedOrders(ordersToSelect);
        }
        setIsAllSelected(!isAllSelected);
      };
    
      const handleSelectOrder = (id) => {
        const newSelectedOrders = selectedOrders.includes(id)
          ? selectedOrders.filter((orderId) => orderId !== id)
          : [...selectedOrders, id];
        
        setSelectedOrders(newSelectedOrders);
        setIsAllSelected(newSelectedOrders.length === orderData.length);
      };
      //order settle function
      const handleSettle = () => {
        if (!mode || !settledate || !notes) {
          swalWithBootstrapButtons.fire({
            title: "Error",
            text: "All fields are required!",
            icon: "error",
          });
          return;
        } 
        // Additional check for Transaction Id when mode requires it
        if ((mode === "UPI" || mode === "NEFT" || mode === "IMPS") && !transctionid) {
          swalWithBootstrapButtons.fire({
            title: "Error",
            text: "Transaction Id is required for selected payment mode!",
            icon: "error",
          });
          return;
        }
      
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You want to settle these orders!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
           
          })
          .then((result) => {
            if (result.isConfirmed) {
              setLoading(true); 
              axios
                .post(BASE_URL_API + "orders/adminSettlment",{
                  orderID: selectedOrders,
                  mode: mode,
                  transctionId: transctionid,
                  settlement_date: formatDateddmmyy(settledate),
                  notes: notes
                })
                .then((res) => {
                  swalWithBootstrapButtons.fire(
                    "Updated!",
                    "These orders have been settled successfully.",
                    "success"
                  );
                  console.log(res.data);
                  setSattle(false);
                  fetchData();
                  setIsAllSelected(false);
                  setSelectedOrders([]);
                  setMode("");
                  setTransactionId("");
                  setSettleDate("");
                  setNotes("");
                })
                .catch((err) => console.log(err))
                .finally(() => {
                  setLoading(false);
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire(
                "Cancelled",
                "You did not settled these orders. Please try again",
                "error"
              );
              setSattle(false);
              setMode("");
              setTransactionId("");
              setSettleDate("");
              setNotes("");
            }
          });
      };
      const backpage =() =>{
        navigate(-1);
      }
  return (
    <div className='card'>
     <div className="d-flex justify-content-between"> 
     <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
     <h5 >Settle Orders</h5>        
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">      
        <button type="button" className="btn btn-md" style={{color:"white", backgroundColor:"red"}} 
        onClick={backpage}>Go Back</button>
        &nbsp;&nbsp;
        <button type="button" className="btn btn-md" style={{color:"white", backgroundColor:"#73be44"}} 
        onClick={()=>setSattle(true)} disabled={selectedOrders.length === 0} >Order Settlement</button>
        </div>
      </div>
        <div
          className="table-responsive table-bordered"
          style={{ minHeight: "83vh", overflowY: "auto" }}
        >
          <table className="table mb-0">
            <thead style={{backgroundColor:"#E6F8DB"}}>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                  />
                </th>
                {/* <th>Sl no</th> */}
                <th>
                  Order ID
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={orderId}
                    onChange={(e) => {setOrderId(e.target.value);setIsAllSelected(false); setSelectedOrders([]);}}
                  />
                </th>
                <th>
                  Customer Name
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={customername}
                    onChange={(e) => {setCustomerName(e.target.value); setIsAllSelected(false); setSelectedOrders([]);}}
                  />
                </th>
                <th>
                  Contact No
                  <br />
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    value={mobileno}
                    onChange={(e) => {setMobileNo(e.target.value); setIsAllSelected(false); setSelectedOrders([]);}}
                  />
                </th>
                <th>
                  Pickup Date
                  <br />
                  <input
                    type="date"
                    className="form-control"
                    style={{ width: "100%" }}
                    value={scdate}
                    onChange={(e) => {setScdate(e.target.value); setIsAllSelected(false); setSelectedOrders([]);}}
                    min={fromDate}
                    max={toDate}
                  />
                </th>
                <th>
                  Status <br />
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px" }}>
              {orderData
                .map((data, index) => {
                  const isChecked = selectedOrders.includes(data.order_id);
                  const isPaymentPending = data.status === 'Pickup Completed';
                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={() => handleSelectOrder(data.order_id)}
                          disabled={!isPaymentPending}
                        />
                      </td>
                      <td>{data.order_id}</td>
                      <td>{data.customer_name}</td>
                      <td>{data.customer_mobile}</td>
                      <td>{data.schedule_date}</td>
                      <td>{data.status === 'processed' ? 'Payment Successful' : data.status === 'Pickup Completed' ? 'Payment Pending' : data.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      
      <Modal
        size="md"
        show={sattle}
        onHide={() => setSattle(false)}
        aria-labelledby="example-modal-sizes-title-md" centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Payment Settlement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Mode Of Payment </Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Form.Control as="select" className='form-select form-select-md' value={mode} onChange={(e) => setMode(e.target.value)}>
                  <option value="" disabled >Select Mode</option>
                  <option value="Cash">Cash</option>
                  <option value="Eco">Eco</option>
                  <option value="IMPS">IMPS</option>
                  <option value="NEFT">NEFT</option>
                  <option value="UPI">UPI</option>
                </Form.Control>
                </Col>
              </Row>
            </Form.Group>
            {(mode === "UPI" || mode === "NEFT" || mode === "IMPS" ) && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Transaction Id / UTR</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <Form.Control
                    type="text"
                    value={transctionid}
                    onChange={(e) => setTransactionId(e.target.value)}
                  />
                </Col>
              </Row>
              </Form.Group>
            )}
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Row>
                  <Col xs={6} md={3}>
                    <Form.Label>Settlement Date</Form.Label>
                  </Col>
                  <Col xs={12} md={9}>
                  <Form.Control
                    type="date"
                    value={settledate}
                    onChange={(e) => setSettleDate(e.target.value)}
                  />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={3}>
                  <Form.Label>Notes</Form.Label>
                </Col>
                <Col xs={12} md={9}>
                <textarea
                    className="form-control"
                    id="example-modal-sizes-title-md"
                    type="text-area"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows="3"
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
          )}
        </Modal.Body>
        {!loading && (
        <Modal.Footer>
          <Button variant="primary" onClick={handleSettle}>
            Save Changes
          </Button>
        </Modal.Footer>
         )}
      </Modal>
    </div>
  )
}

export default OrderSettlement
