import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BASE_URL_API } from "../environment";
import { Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Swal from "sweetalert2";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import * as XLSX from 'xlsx';

export const Locate = () => {
  const [loc, setLoc] = useState([]);
  const [cityid, setCityId] = useState(null);
  const [selectcityid, setSelectcityid] = useState("");
  const [uploadcityid, setUploadCityId] = useState(null);
  const [uploadcity, setUploadCity] = useState(null);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });


  //fetching all location and showing in table
  const fetchData = () => {
    axios
    .get(BASE_URL_API + "location")
    .then((res) => {
      if (selectcityid === "") {
        setLoc(res.data);
      } else {
        const filteredData = res.data.filter(item => item.cityid === selectcityid);
        console.log(filteredData)
        setLoc(filteredData);
      }
    })
    .catch((err) => console.log(err));

    axios
      .get(BASE_URL_API + "items/allCityPercent")
      .then((res) => setCityId(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [selectcityid]);


  //delete location
  const apiDelete = (id) => {
    

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to delete this location !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(BASE_URL_API + "location/delete/" + id)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Done!",
                "Location Deleted Successfully .",
                "success"
              );
              console.log(res.data);
              fetchData();
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your Location is Still in List.",
            "error"
          );
        }
      });
  };

  const [locate, setLocate] = useState({
    PIN: "",
    location: "",
    city: "",
    cityid: "",
    state: "",
  });



  //deactivate location
const locationInactive = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to deactivate this location !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .put(BASE_URL_API + "location/deactivate/" + id)
        .then((res) => {
          Swal.fire("Deactivated!", "This location has been deactivated.", "success");
          // console.log(res.data);
          fetchData();
        })
        .catch((err) => console.log(err));
    }
  });
};


//activate location
const locationActive = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to activate this location !",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .put(BASE_URL_API + "location/activate/" + id)
        .then((res) => {
          Swal.fire("Activated!", "This location has been activated.", "success");
          // console.log(res.data);
          fetchData();
        })
        .catch((err) => console.log(err));
    }
  });
};


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLocate({ ...locate, [name]: value });
  };

  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    setUploadCityId(selectedCityId);
    const selectedCity = cityid.cities.find((city) => city._id === selectedCityId);
    setUploadCity(selectedCity.city);
    if (selectedCity) {
      setLocate({ ...locate, city: selectedCity.city, cityid: selectedCity._id });
    }
  };
  //add new location
  const addLocation = () => {
    if (!locate.PIN || !locate.location || !locate.city || !locate.state || !locate.cityid) {
      Swal.fire({
        icon: "error",
        title: "All fields must be filled",
        text: "Please fill in all the fields before adding the location.",
      });
      setLocate({
        location: "",
        city: "",
        cityid: "",
        state: "",
        PIN: "",
      });
      return;
    }

    if (locate.PIN.length !== 6) {
      Swal.fire({
        icon: "error",
        title: "Invalid PIN length",
        text: "PIN length must be exactly 6 characters.",
      });
      setLocate({
        location: "",
        city: "",
        cityid: "",
        state: "",
        PIN: "",
      });
      return;
    }

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to add this location !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(BASE_URL_API + "location/create", locate)
            .then((res) => {

              console.log(res)
              if (res.data.uploaded === false  && res.data.message !== "Update location."){
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "This location already exists.",
                  "error"
                );
              }else if(res.data.uploaded === false && res.data.message === "Update location."){
                swalWithBootstrapButtons.fire(
                  "Success!",
                  "A new location added successfully.",
                  "success"
                );
              }else if(res.data.error === false){
                swalWithBootstrapButtons.fire(
                  "Success!",
                  "A new location added successfully.",
                  "success"
                );
              }else{
                swalWithBootstrapButtons.fire(
                  "Error !",
                  "An error occur , try again ..!",
                  "error"
                );
              }
              
              viewModal(false);
              fetchData();
              setLocate({
                location: "",
                city: "",
                cityid: "",
                state: "",
                PIN: "",
              });
            })
            .catch((err) =>{
              console.log(err);
              swalWithBootstrapButtons.fire(
                "Error !",
                "An error occur , try again ..!",
                "error"
              );
            } );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not add this Location :)",
            "error"
          );
          viewModal(false);
          fetchData();
          setLocate({
            location: "",
            city: "",
            cityid: "",
            state: "",
            PIN: "",
          });
        }
      });
  };

  const addModal = () => {
    viewModal(false);
    setLocate({
      location: "",
      city: "",
      cityid: "",
      state: "",
      PIN: "",
    });

  }

  const closebulkmodal = () => {
    bulkModal(false);
    setSelectedFile(null);
    setUploadCity(null);
    setUploadCityId(null);
  }

  //action button of table
  const actionsFormatter = (dataField, row) => {
    if (row.isActive) {
      return (
        <>
          <Button
              type="button"
              className="btn btn-danger btn-sm mx-1"
              onClick={() => apiDelete(dataField)}
            >
              <i className="fa-solid fa-trash me-1"></i>{" "}
            </Button>
            <Button
              type="button"
              className="btn btn-sm mx-1"
              onClick={() => apiUpdate(dataField)}
              style={{background:"#73be44", color:"#fff"}}
            >
              <i className="fa-solid fa-edit me-1"></i>{" "}
            </Button>
          <Button
            type="button"
            className="btn btn-primary btn-sm mx-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Inactive"
            onClick={() => locationInactive(dataField)}
          >
            <i className="fa-solid fa-toggle-on" aria-hidden="true"></i>
          </Button>
        </>
      );
    } else {
      return (
        <>
        <Button
              type="button"
              className="btn btn-light btn-sm mx-1 disabled"
              
            >
              <i className="fa-solid fa-trash me-1"></i>{" "}
            </Button>
            <Button
              type="button"
              className="btn btn-light btn-sm mx-1 disabled"
            >
              <i className="fa-solid fa-edit me-1"></i>{" "}
            </Button>
          <Button
          type="button"
          className="btn btn-primary btn-sm mx-1"
          data-toggle="tooltip"
            data-placement="top"
            title="Active"
            onClick={() => locationActive(dataField)}
        >
          <i className="fa-solid fa-toggle-off" aria-hidden="true"></i>
        </Button>
        </>
        
      );
    }
  }; 

  //column for data table
  const columns = [
    {
      dataField: "location",
      text: "Service Location",
      sort: "asc",
      filter: textFilter(),
    },
    {
      dataField: "city",
      text: "City",
      sort: "asc",
      filter: textFilter(),
    },
    {
      dataField: "state",
      text: "State",
      sort: "asc",
      filter: textFilter(),
    },
    {
      dataField: "PIN",
      text: "PIN",
      sort: "asc",
      filter: textFilter(),
    },
    {
      dataField: "isActive",
      text: "Status",
      formatter: (isActive) => {
        return isActive ? "Active" : "Inactive";
      },
    },
    
    {
      dataField: "_id",
      text: "Actions",
      formatter: actionsFormatter,
    },
  ];

  const rowClasses = (row) => {
    return row.isActive ? "table-lightgreen" : ""; 
  };

  //pagination
  const [sizePerPage, setSizePerPage] = useState(10);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };

  //pincode check and getting the state
  const handlePinBlur = () => {
    const pin = locate.PIN;
    if (pin) {
      axios
        .get(`https://api.postalpincode.in/pincode/${pin}`)
        .then((response) => {
          const data = response.data;
          if (data && data.length > 0 && data[0].PostOffice.length > 0) {
            // const city = data[0].PostOffice[0].District;
            const state = data[0].PostOffice[0].State;
            setLocate({ ...locate, state });
          } else {
            console.log("Invalid PIN");
            Swal.fire({
              icon: "error",
              title: "Invalid PIN",
              text: "Please fill a valid pin for adding the location.",
            });
            setLocate({ ...locate, state: "" });
          }
        })
        .catch((error) => {
          console.error("Error fetching PIN details:", error);
        });
    } else {
      setLocate({ ...locate, state: "" });
    }
  };

 
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [updateLocation, setUpdateLocation] = useState({
    _id: "",
    location: "",
    city: "",
    state: "",
  });

  const openUpdateModal = (location) => {
    setUpdateModalVisible(true);
    setUpdateLocation(location);
  };

  const closeUpdateModal = () => {
    setUpdateModalVisible(false);
    setUpdateLocation({
      _id: "",
      location: "",
      city: "",
      state: "",
    });
  };

  //function for getting location by id
  const apiUpdate = (id) => {
    const fetchLocationById = (id) => {
      axios
        .get(BASE_URL_API +`location/${id}`)
        .then((response) => {
          const location = response.data[0];
          setUpdateLocation(location);
          openUpdateModal(location);
        })
        .catch((error) => console.error("Error fetching location by ID:", error));
    };
    fetchLocationById(id);
  };

  const handleUpdateInputChange = (event) => {
    const { name, value } = event.target;
    setUpdateLocation({ ...updateLocation, [name]: value });
  };

  //update function
  const update = () => {
    if (!updateLocation.location || !updateLocation.city || !updateLocation.state) {
      Swal.fire({
        icon: "error",
        title: "All fields must be filled",
        text: "Please fill in all the fields before adding the location.",
      });
      
      return;
    }
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this location !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .put(BASE_URL_API + `location/update`, updateLocation)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Done!",
                "Location Updated Successfully.",
                "success"
              );
              closeUpdateModal();
              fetchData();
            })
            .catch((err) => {
              console.error("Error updating location:", err);
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Location is not updated .",
            "error"
          );
        }
      });
  };
  
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Function to handle file upload
  const handleUpload = () => {
    if (!selectedFile || uploadcity === null  ) {
      window.alert('Please select a file and enter city name');
      return;
    }

    // Read the selected Excel file
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      let jsonData = XLSX.utils.sheet_to_json(sheet);
      const city = uploadcity;
      const cityid = uploadcityid;
  
      // Code for adding city and cityid for all row 
      jsonData = jsonData.map((row) => ({
        ...row,
        city: city,
        cityid: cityid
      }));
      axios.post(BASE_URL_API + 'location/addBulkLocation', jsonData)
        .then((res) => {
          console.log(res.data.message);
          if (res.data.message === "Locations saved successfully"){
            fetchData();
            closebulkmodal();
            window.alert(res.data.message);
          } else {
            fetchData();
            closebulkmodal();
            window.alert(res.data.message);
          }
        })
        .catch((error) => {
          closebulkmodal();
          console.log(error.response.data[0].uploaded);
          window.alert(error.response.data[0].message);
        });
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const [modal, viewModal] = useState(false);
  const [bulk, bulkModal] = useState(false);
  return (
    <div className="card">
      <h5 className="card-header">Service Location</h5>
      <div className="d-flex justify-content-between"> 
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
        <select onChange={(e)=> setSelectcityid(e.target.value)} className="form-select form-select-sm " style={{ width: '150px', height: '40px', borderColor:"#73be44" }}>
        <option value="">All Cities</option>
          {cityid && cityid.cities
            ? cityid.cities.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.city}
                </option>
              ))
            : null}
        </select>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end p-2 ">
      <Button className="btn" style={{background:"#73be44", color:"#fff"}} onClick={() => bulkModal(true)}><i className="fa-solid fa-file-import"></i>&nbsp;&nbsp;Import Location</Button>
      <Button className="btn" style={{background:"#73be44", color:"#fff"}} onClick={() => viewModal(true)}>
        <i className="fa-solid fa-plus"></i>&nbsp;Add New Location
      </Button>
      </div>
      </div>

      {/* SHowing data in table  */}
      <BootstrapTable
        keyField="id"
        data={loc}
        columns={columns}
        pagination={paginationFactory(options)}
        search
        filter={filterFactory()}
        rowClasses={rowClasses}
      />

      {/* Add Location Modal */}
      <Modal
        size="md"
        show={modal}
        onHide={addModal}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Add New Service Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>PIN</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Pin"
                    name="PIN"
                    value={locate.PIN}
                    onChange={handleInputChange}
                    onKeyUp={handlePinBlur}
                    onKeyPress={(event) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(event.charCode);

                      if (
                        event.target.value.length === 0 &&
                        inputChar === "0"
                      ) {
                        event.preventDefault();
                      }

                      if (!pattern.test(inputChar)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength={6}
                    autoFocus
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Location</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    name="location"
                    value={locate.location}
                    onChange={handleInputChange}
                    placeholder="Enter Location"
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <select onChange={(e) => handleCityChange(e)} className="form-select" defaultValue="" >
                    <option value="" disabled>
                      Select a City
                    </option>
                    {cityid && cityid.cities
                      ? cityid.cities.map((city) => (
                          <option key={city._id} value={city._id}>
                            {city.city}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>State</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Enter State"
                    name="state"
                    value={locate.state}
                    onChange={handleInputChange}
                    // disabled
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={addLocation}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    
    {/* bulk location insert modal */}
    <Modal
        size="md"
        show={bulk}
        onHide={closebulkmodal}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Import Location File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <select onChange={(e) => handleCityChange(e)} className="form-select" defaultValue="" >
                    <option value="" disabled>
                      Select a City
                    </option>
                    {cityid && cityid.cities
                      ? cityid.cities.map((city) => (
                          <option key={city._id} value={city._id}>
                            {city.city}
                          </option>
                        ))
                      : null}
                  </select>
                </Col>
              </Row>
            </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <div className="d-flex justify-content-between pt-3 ">
        <a href="/ServiceLocationFormat.xlsx" download className="button-link mx-auto col-5" style={{color:"#73be44"}}>
        Download Format
        </a>
        <input type="file" accept=".xlsx, .csv" onChange={handleFileChange} className="form-control col-7" /> 
        </div>

        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpload}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={updateModalVisible}
        onHide={closeUpdateModal}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Update Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Location</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    name="location"
                    value={updateLocation.location}
                    onChange={handleUpdateInputChange}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    name="city"
                    value={updateLocation.city}
                    onChange={handleUpdateInputChange}
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>State</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Enter State"
                    name="state"
                    value={updateLocation.state}
                    onChange={handleUpdateInputChange}
                    // disabled
                    required
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={update}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
