import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL_API, IMG_URL } from "../environment";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Swal from "sweetalert2";
import noLogo from '../img/logo01.svg';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

function ItemCategory() {

    const [add, setAdd] = useState(false);
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);

    const [catName, setCatname] =useState("");
    const [image, setImage] = useState({});
    const [description, setDescription] = useState("");
    const[emission,setEmission]=useState("");
    const [allCat, setAllcat]= useState([]);

    useEffect(() => {
       fetchData();
    }, []);

//fetching data and showing in table
    const fetchData = () => {
        axios
          .get(BASE_URL_API + "itemcategory/getall")
          .then((res) => {
            console.log(res.data.item);
            setAllcat(res.data.item);
            
          })
          .catch((err) => console.log(err));
      };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      const [itemCatLang, setItemCatLang] = useState([
        { language: 'EN', item_name: ''},
        { language: 'HI', item_name: ''},
        { language: 'OD', item_name: ''},
      ]);

      let isFirstTime = true;
      const handleItemChange = (e, index) => {
        const { name, value } = e.target;
        const langIndex = name.split('_')[2];
      
        if (isFirstTime && langIndex === '0') {
          setCatname(e.target.value.trim())
          isFirstTime = false; 
        }
      
        setItemCatLang((prevItemLang) => {
          const updatedItemLang = [...prevItemLang];
          updatedItemLang[langIndex].item_name = value.trim();
          return updatedItemLang;
        });
      };
      //function for adding category
      const handleApiAdd = () => {
        if (!catName || !image ||!emission) {
          swalWithBootstrapButtons.fire({
            title: "Error",
            text: "Either Name or Image or Emission is missing!",
            icon: "error",
          });
          return;
        }
        // const itemCatLang = [{ "item_name": "ସ୍କ୍ରାପ୍ ପେପର |", "language": "OD" }, { "item_name": "रद्दी कागज", "language": "HI" }]
        const formData = new FormData();
        formData.append("image", image);
        formData.append("item_name", catName.trim());
        formData.append("emission",emission.trim());
        if (description !== undefined) {
          formData.append("description", description);
        } else {
          formData.append("description", "");
        }
        formData.append("itemCatLang", JSON.stringify(itemCatLang));
    
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You want to add this category !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            
          })
          .then((result) => {
            if (result.isConfirmed) {
              axios
                .post(BASE_URL_API + "itemcategory/create", formData)
                .then((res) => {
                  if (res.data.uploaded === false) {
                    swalWithBootstrapButtons.fire(
                      "Failed to add !",
                      "This category already exists.",
                      "error"
                    );
                    setCatname("");
                    setDescription("");
                    setImage(null);
                    setEmission("");
                    setItemCatLang([
                      { language: 'EN', item_name: ''},
                      { language: 'HI', item_name: ''},
                      { language: 'OD', item_name: ''},
                    ]);
                    setAdd(true);
    
                  } else {
                    swalWithBootstrapButtons.fire(
                      "Added!",
                      "A new category added successfully.",
                      "success"
                    );
                    setCatname("");
                    setDescription("");
                    setImage(null);
                    setEmission("");
                    setItemCatLang([
                      { language: 'EN', item_name: ''},
                      { language: 'HI', item_name: ''},
                      { language: 'OD', item_name: ''},
                    ]);
                    setAdd(false);
                    fetchData();
                    
                  }
                })
                .catch((err) => console.log(err));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire(
                "Cancelled",
                "You did not add this product",
                "error"
              );
              setCatname("");
                setDescription("");
                setImage(null);
                setEmission("");
                setItemCatLang([
                  { language: 'EN', item_name: ''},
                  { language: 'HI', item_name: ''},
                  { language: 'OD', item_name: ''},
                ]);
                setAdd(false);
            //   fetchData();
              
            }
          });
      };

    const hideModal = () => {
        setAdd(false);
        setCatname("");
        setDescription("");
        setImage({});
      };


      //view item category
      const [catView, setCatview] = useState([]);

      const viewBtn = (idd) => {
        handleApiView(idd);
        setView(true);
      };
    
      const handleApiView = (id) => {
        axios
          .get(BASE_URL_API + "itemcategory/getind/" + id)
          .then((res) => {
            setCatview([res.data.item]);
          })
          .catch((err) => console.log(err));
      };

      const actionsFormatter = (dataField, row) => {
        if (row.isActive) {
          return (
            <>
              <Button
                type="button"
                className="btn btn-sm "
                onClick={() => viewBtn(dataField)}
                data-toggle="tooltip"
                data-placement="top"
                title="View Details"
                style={{background:"#73be44", color:"#fff"}}
              >
                <i className="fa-solid fa-eye "></i>
              </Button>
              <Button
                type="button"
                className="btn btn-secondary btn-sm mx-1"
                onClick={() => editBtn(dataField)}
                data-toggle="tooltip"
                data-placement="top"
                title="Edit"
              >
                <i className="fa-solid fa-edit "></i>
              </Button>
              {/* <Button
                type="button"
                className="btn btn-danger btn-sm "
                onClick={() => apiDelete(dataField)}
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              >
                <i className="fa-solid fa-trash "></i>
              </Button> */}
              {/* <Button
                type="button"
                className="btn btn-primary btn-sm mx-1"
                // onClick={() => productsInactive(dataField)}
                data-toggle="tooltip"
                data-placement="top"
                title="Inactive"
              >
                <i className="fa-solid fa-toggle-on" aria-hidden="true"></i>
              </Button> */}
            </>
          );
        } else {
          return (
            <>
            <Button
                type="button"
                className="btn btn-light btn-sm disabled "
              >
                <i className="fa-solid fa-eye "></i>
              </Button>
              <Button
                type="button"
                className="btn btn-light btn-sm disabled mx-1"
               
              >
                <i className="fa-solid fa-edit "></i>
              </Button>
              {/* <Button
                type="button"
                className="btn btn-light btn-sm disabled"
                onClick={() => apiDelete(dataField)}
                
              >
                <i className="fa-solid fa-trash "></i>
              </Button> */}
              {/* <Button
              type="button"
              className="btn btn-primary btn-sm mx-1"
            //   onClick={() => productsActive(dataField)}
              data-toggle="tooltip"
                data-placement="top"
                title="Active"
            >
              <i className="fa-solid fa-toggle-off" aria-hidden="true"></i>
            </Button> */}
            </>
            
          );
        }
      };

      //update item category
  const [cid, setCid]=useState("");
const editBtn = (idd) => {
    axios
      .get(BASE_URL_API + "itemcategory/getind/" + idd)
      .then((res) => {
        const item = res.data.item;
        setCid(idd);
        setCatname(item.item_name);
        setDescription(item.description);
        setEmission(item.emission);
        setImage(item.imageURL);
        if (item.itemCatLang.length === 0) {
          setItemCatLang([
            { language: 'EN', item_name: ''},
            { language: 'HI', item_name: ''},
            { language: 'OD', item_name: ''},
          ]);
        } else {
          const updatedItemLang = item.itemCatLang.map((langItem) => ({
            language: langItem.language,
            item_name: langItem.item_name,           
          }));
          setItemCatLang(updatedItemLang);
        } 
        setEdit(true);
      })
      .catch((err) => console.log(err));
    setEdit(true);
  };

  //update function for item category
  const handleApiUpdate = (id) => {
    if (!catName|| !image ||!emission) {
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: "Either Name or Image or Emission is missing!",
        icon: "error",
      });
      return;
    }
  
    const formData = new FormData();
    formData.append("item_name", catName);
    formData.append("description", description);  
    formData.append("emission",emission);
    formData.append("itemCatLang", JSON.stringify(itemCatLang));
    if (image) {
      formData.append("image", image);
    }
  
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to update this category!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios
            .post(BASE_URL_API + "itemcategory/update/"+ cid, formData)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Updated!",
                "This category details have been updated successfully.",
                "success"
              );
              console.log(res.data);
              setEdit(false);
              setDescription("");
              setEmission("");
              setCatname("");
              setItemCatLang([
                { language: 'EN', item_name: ''},
                { language: 'HI', item_name: ''},
                { language: 'OD', item_name: ''},
              ]);
              setImage(null);
              fetchData();
            })
            .catch((err) => console.log(err));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "You did not update this product",
            "error"
          );
          setEdit(false);
        }
      });
  };
  //pagination function
      const [sizePerPage, setSizePerPage] = useState(10);

      const handleTableChange = (type, { page, sizePerPage }) => {
        setSizePerPage(sizePerPage);
      };
    
      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      );
    
      const options = {
        paginationSize: sizePerPage,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        hideSizePerPage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        onTableChange: handleTableChange,
      };

      const rowClasses = (row) => {
        return row.isActive ? "table-lightgreen" : ""; 
      };
      
      //column for showing in data table
      const columns = [
        {
          dataField: "imageURL",
          text: "Image",
          formatter: (dataField) => {
            if (dataField) {
              return (
                <img
                  src={IMG_URL + dataField}
                  style={{ height: "50px", width: "50px" }}
                  className="rounded"
                />
              );
            } else {
              return <img src={noLogo} style={{ height: "50px", width: "50px" }} className="rounded" />;
            }
          },
          headerStyle: { width: "75px" },
        },
        {
          dataField: "item_name",
          text: "Name",
          sort: "asc",
          filter: textFilter(),
          
        },
        {
          dataField: "description",
          text: "Description",
          sort: "asc",
                
        },
        {
          dataField: "emission",
          text: "Emission",
          sort: "asc",
          headerStyle: { width: '150px' }        
        },
        {
          dataField: "_id",
          text: "Actions",
          formatter: actionsFormatter,
        },
      ];

      const hideeditModal = () => {
        setEdit(false);
        setCatname("");
        setDescription("");
        setImage({});
      };
  return (
    <div className="card">
      <h5 className="card-header">Category </h5>
      <Button className="btn mb-3" style={{background:"#73be44", color:"#fff"}} onClick={() => setAdd(true)}>
        <i className="fa-solid fa-plus"></i>Add new Category
      </Button>

{/* shouing data in data table */}
      <BootstrapTable
          keyField="id"
          data={allCat}
          columns={columns}
          pagination={paginationFactory(options)}
          search
          filter={filterFactory()}
          rowClasses={rowClasses}
        />

      

      {/* Add Products modal */}
      <Modal
        size="md"
        show={add}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Add Another Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Language</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Row>
                    {itemCatLang.map((langItem, index) => (
                      <Col key={langItem.language} xs={4} md={4}>
                        <Form.Label>{langItem.language}</Form.Label>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Category Name: </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                <Row>
                  {itemCatLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Category Name"
                        name={`item_name_${index}`}
                        value={langItem.item_name}
                        onChange={(e) => handleItemChange(e, index)}
                      />
                    </Col>
                  ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                onChange={(e) => setDescription(e.target.value)}
                rows={2}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Emission</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setEmission(e.target.value)}
               
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApiAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

{/* View modal Start */}
      <Modal
        size="md"
        show={view}
        onHide={() => setView(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {catView.map((category) => (
                <>
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
  {category.imageURL ? (
    <img src={IMG_URL + category.imageURL} style={{ height: "75px", width: "75px" }} />
  ) : (
    <img src={noLogo} style={{ height: "75px", width: "75px" }} />
 ) }
</Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Category</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <h4>{category.item_name}</h4>
                    </Col>
                  </Row>
                  <br />
                   <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Description</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <p>{category.description}</p>
                    </Col>
                  </Row>

                   {/* <br />
                   <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Description</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <p>{category.descript}</p>
                    </Col>
                  </Row> */}
                  <br/>
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Emission</Form.Label>
                    </Col>
                    <Col xs={12} md={8}>
                      <p>{category.emission}</p>
                    </Col>
                  </Row>
                </>
              ))}
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      {/* View modal End */}

      {/* Edit modal start */}
      <Modal
        size="md"
        show={edit}
        onHide={hideeditModal}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
           <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Language</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Row>
                    {itemCatLang.map((langItem, index) => (
                      <Col key={langItem.language} xs={4} md={4}>
                        <Form.Label>{langItem.language}</Form.Label>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Category Name: </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                <Row>
                  {itemCatLang.map((langItem, index) => (
                    <Col key={langItem.language} xs={4} md={4}>
                      <Form.Control
                        type="text"
                        placeholder="Category Name"
                        name={`item_name_${index}`}
                        value={langItem.item_name}
                        onChange={(e) => handleItemChange(e, index)}
                      />
                    </Col>
                  ))}
                </Row>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Label>Image</Form.Label>
                    </Col>
                    <Col xs={12} md={2}>
                      <img
                        src={IMG_URL +image} style={{ height: "75px", width: "75px" }}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Control
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        placeholder="Image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </Col>
                  </Row>
                  </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> 
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Description</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Emission</Form.Label>
              <Form.Control
                type="text"
                value={emission}
                onChange={(e) => setEmission(e.target.value)}
               
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleApiUpdate(cid)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ItemCategory
