import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { BASE_URL_API } from '../environment';
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';

function Cities() {
  const [cityPercent, setCityPercent] = useState([]);
  const [city, setCity]= useState("");
  const [citypercentage, setCityPercentage]= useState("");

  useEffect(() => {
    fetchData();
  }, []);

  //fetching cities and showing in table
  const fetchData = () => {
    axios.get(BASE_URL_API + "items/allCityPercent")
      .then(res => {
        console.log(res.data);
        setCityPercent(res.data.cities); 
      })
      .catch(err => console.log(err));
  };

  //showing data in column wise in data table
  const columns = [
    {
      dataField: 'city',
      text: 'City',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'percentage',
      text: 'Percentage',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'isActive',
      text: 'Active',
      sort: true,
      formatter: (cellContent, row) => (
        <span>{cellContent ? 'Yes' : 'No'}</span>
      ),
    },
  ];
const [addModal, setAddModal] = useState(false);
//hidemodal
const hideModal = () => {
  setCity("");
  setCityPercentage("");
  setAddModal(false);
}
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

//add city function
const handleApiAdd = () => {
  if (!city || !citypercentage) {
    swalWithBootstrapButtons.fire({
      title: "Error",
      text: "All fields are required!",
      icon: "error",
    });
    return;
  }
  
  swalWithBootstrapButtons
    .fire({
      title: "Are you sure?",
      text: "You want to add this city !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      
    })
    .then((result) => {
      if (result.isConfirmed) {
        axios
          .post(BASE_URL_API + "items/addCityPercent", {
            city: city,
            percentage: citypercentage
          })
          .then((res) => {
            if (res.data.uploaded === false) {
              swalWithBootstrapButtons.fire(
                "Failed to add !",
                "This product already exists.",
                "error"
              );
              
              setCity("");
              setCityPercentage("");
              setAddModal(true);

            } else {
              swalWithBootstrapButtons.fire(
                "Added!",
                "A new product has been Added.",
                "success"
              );
              setCity("");
              setCityPercentage("");
              setAddModal(false);
              fetchData();
            }
          })
          .catch((err) => console.log(err));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        swalWithBootstrapButtons.fire(
          "Cancelled",
          "You did not add this product",
          "error"
        );
        setCity("");
        setCityPercentage("");
        setAddModal(false);        
      }
    });
};
  return (
    <div className="card">
      <h5 className="card-header">Cities</h5>
      <div className="d-flex justify-content-end">
        <div className="d-grid gap-2 d-md-flex justify-content-md-end p-2 float-end">
          <Button className="btn" onClick={() => setAddModal(true)} style={{ background: "#73be44", color: "#fff" }}>
            <i className="fa-solid fa-plus"></i>Add New City & Percentage
          </Button>
        </div>
      </div>
      {/* SHowing data in table  */}
      <BootstrapTable
        keyField='id'
        data={cityPercent}
        columns={columns}
        filter={filterFactory()}
        search
      />
      {/* modal for adding new city */}
      <Modal
        size="sm"
        show={addModal}
        onHide={hideModal}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Add New City and Percentage
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>City </Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="City Name"
                    onChange={(e) => setCity(e.target.value.trim())}
                    autoComplete='off'
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Percentage</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="text"
                    placeholder="Percentage"
                    onChange={(e) => setCityPercentage(e.target.value.trim())}
                   autoComplete='off'
                   onKeyPress={(event) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(event.charCode);

                    if (
                      event.target.value.length === 0 &&
                      inputChar === "0"
                    ) {
                      event.preventDefault();
                    }

                    if (!pattern.test(inputChar)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={2}
                  />
                </Col>
              </Row>
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleApiAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Cities;
