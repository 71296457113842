import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BASE_URL_API, IMG_URL } from '../environment';
import { Form, Row, Col } from 'react-bootstrap'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import noLogo from '../img/logo01.svg';
import validation from 'validator';
import { Link, useNavigate } from 'react-router-dom';


export const AgentSettlement = () => {
  const navigate = useNavigate();
  const [agent, setAgent] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState();
  const [offset, setOffset] = useState(1);
  const [paymentdata, setPaymentData] = useState([]);
  const [totalData, setTotaldata] = useState(null);
  const [settledAmount, setSettledAmount] = useState('');
  const [pendingAmount, setPendingAmount] = useState('');
  const [Advance, setAdvance] = useState('');
  const [lastSettledDate, setLastSettledDate] = useState('');
  const [showSection, setShowSection] = useState("");
  const [settledHistory, setSettledHistory] = useState([]);
  const [pendingamountHistory, setPendingAmountHistory] = useState([]);
  const [advanceHistory, setAdvanceHistory] = useState([]);
  const [settled, setSettled] = useState(false);
  const [pending, setPending] = useState(false);
  const [advance, setadvance] = useState(false);
  const [view, setView] = useState(false);
  const [amount, setAmount] = useState("");
  const [modeOfAdvance, setModeOfAdvance] = useState("");
  const [date, setDate] = useState("");
  const [advanceView, setAdvanceView] = useState(false);
  const [modeofSettlement, setModeofSettlement] = useState('');
  const [settlementMode, setSettlementMode] = useState('IMPS');
  // const [upiId,setUpiId]=useState('');
  const [bank_account_number, setBank_account_number] = useState('');
  const [ifsc_number, setIfsc_number] = useState('');
  const [Name, setName] = useState('');
  const [bankAccounts, setBankAccounts] = useState([]);
  const [upiDetails, setUpiDetails] = useState('');
  const [selectedUpi, setSelectedUpi] = useState('');
  // const [settlementMode, setSettlementMode] = useState(""); // No pre-selected option
  // const [selectedUpi, setSelectedUpi] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);
  const [otp, setOtp] = useState('');
  const [showotpverifyModal, setShowOtpModal] = useState(false);
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);
  const [single_settle_model, Set_single_settle_model] = useState(false);
  const [checkAdvance, SetAdvance] = useState(false);
  const [fullsettle, SetfullSettle] = useState(false);
  const [singleSettle, setSingleSettle] = useState(false);
  const today = new Date();
  const formattedToday = `${String(today.getDate()).padStart(2, "0")}-${String(today.getMonth() + 1).padStart(2, "0")}-${today.getFullYear()}`;
  const [settlementDate, setSettlementDate] = useState(formattedToday);
  const [filterDate, setFilterDate] = useState("");
  const [filterMonth, setFilterMonth] = useState(""); // Month filter state
  const [selectedDays, setSelectedDays] = useState("");
  const [singlesettle_amnt, Setsinglesettle_amnt] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    advance: false,
    payment: false,
  });
  const [secondsetl_modal, setsecond_modal] = useState(false);
  const [highlightFirstRow, setHighlightFirstRow] = useState(false);
  const [trackDate, setTrackDate] = useState('');
  const [fullsettlenote, setfullsettleNote] = useState('');
  const [singleSettleNote, setSinglesettleNote] = useState('');
  const [advanceNote, setAdvanceNote] = useState('');
  const [deductAmount, setDeductAmount] = useState('');
  const [deductionNote, setDeductionNote] = useState('');
  const [grantTotalforFullSettlement, setGrantTotalforFullSettlement] = useState('');
  const [grantTotalforSingleSettlement, setgrantTotalforsingleSettlement] = useState('');
  const [deductNoteforSinglestlmnt, setDeductNoteforSinglestlmnt] = useState('');
  const [deductAmount_single, setDeductAmount_single] = useState('');

  const handleCheckboxChange2 = (e) => {
    const { name, checked } = e.target;

    setSelectedOptions((prev) => {
      let updatedOptions = { ...prev, [name]: checked };

      if (name === "advance") {
        if (checked) {
          // If Advance is 0, disable advance
          if (Advance === 0) {
            updatedOptions.advance = false;
          }
          // If Advance < singlesettle_amnt, disable payment
          else if (Advance < singlesettle_amnt) {
            updatedOptions.payment = false;
          }
        }
      } else if (name === "payment") {
        // If Payment is checked and Advance < singlesettle_amnt, disable advance
        if (checked) {
          if (Advance < singlesettle_amnt) {
            updatedOptions.advance = false;
          }
        } else {
          // If Payment is unchecked, re-enable advance if applicable
          if (Advance >= singlesettle_amnt) {
            updatedOptions.advance = prev.advance; // Restore previous state
          }
        }
      }

      return updatedOptions;
    });
  };

  const fetchGrantTotal = async (amount) => {
    try {
      const response = await axios.post(`${BASE_URL_API}agentdata/granttotalfullsettlement/${selectedAgent}`, {
        deductAmount: amount
      });
      setGrantTotalforFullSettlement(response.data.grantTotal); // Assuming API returns { total: updatedValue }
    } catch (error) {
      console.error("Error fetching grant total:", error);
    }
  };
  //fetch the grant total value for the single value
  const fetchGrantTotalForSingleSettlement = async (amount) => {
    try {
      const response = await axios.post(`${BASE_URL_API}agentdata/granttotalsinglesettlement/${selectedAgent}`, {
        deductAmount: amount,
        paymentIds: selectedTransactionIds,
      });
      setgrantTotalforsingleSettlement(response.data.grantTotal); // Assuming API returns { total: updatedValue }
    } catch (error) {
      console.error("Error fetching grant total:", error);
    }
  };






  const handleAdvanceChange = (e) => {
    const value = parseFloat(e.target.value) || 0; // Ensure value is numeric
    setAdvance(value);

    setSelectedOptions((prev) => {
      let updatedOptions = { ...prev };

      // Disable "Payment" if advance amount is less than `singlesettle_amnt`
      if (value < singlesettle_amnt) {
        updatedOptions.payment = false;
      }

      return updatedOptions;
    });
  };

  const total = pendingAmount - Advance;
  console.log("the bank account details is", bankAccounts);
  console.log("the upi details is", upiDetails);
  const handleDateChange = (e) => {
    const [year, month, day] = e.target.value.split("-");
    setSettlementDate(`${day}-${month}-${year}`); // Convert yyyy-mm-dd to dd-mm-yyyy
  };

  //calculate the advance amount according to the days
  const handleDaySelection = async (days) => {
    setSelectedDays(days);

    if (days) {
      try {
        const response = await fetch(`${BASE_URL_API}agentdata/calculateadvncamnt?days=${days}&agentId=${selectedAgent}`);
        const data = await response.json();
        console.log("data is----", data.data);
        if (response.ok) {
          setAmount(data.data); // Store fetched amount
        } else {
          console.error("Error fetching advance amount:", data.message);
          setAmount(""); // Reset amount on error
        }
      } catch (error) {
        console.error("Error:", error);
        setAmount("");
      }
    } else {
      setAmount("");
    }
  };


  const handleFilterChange = async (e) => {
    e.preventDefault(); // Prevents refreshing issue
    const selectedDate = e.target.value.split("-").reverse().join("-"); // Convert yyyy-mm-dd to dd-mm-yyyy
    setFilterDate(e.target.value);
    try {
      const response = await fetch(
        `${BASE_URL_API}agentdata/getsettlementdata/${selectedAgent}?date=${selectedDate}`
      );
      if (!response.ok) {
        setSettledHistory([]);
      }

      const data = await response.json();
      setSettledHistory(data.data); // Update table with new data
    } catch (error) {
      console.error("Error fetching settled history:", error);
    }
  };
  console.log("the selected data for filter the date wisse is", filterDate);

  //ffilter change function for pending amount
  const handleMonthFilterChange = async (month) => {
    try {
      const response = await fetch(`${BASE_URL_API}agentdata/paymenthistory/${selectedAgent}?month=${month}`);
      const result = await response.json();

      console.log("The data is:", result.data);

      if (response.status === 200 && result.data) {
        setPendingAmountHistory(result.data);
      } else {
        setPendingAmountHistory([]); // Ensure state is reset when no data is found
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
      setPendingAmountHistory([]); // Handle errors by resetting state
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedTransactionIds(pendingamountHistory?.map(data => data._id));
      setSingleSettle(true);
    } else {
      setSingleSettle(false);
      setSelectedTransactionIds([]);
    }
  };
  //for select month
  const handleMonthChange = async (month) => {
    try {
      const response = await fetch(`${BASE_URL_API}agentdata/getsettlementdata/${selectedAgent}?month=${month}`);
      const result = await response.json();

      console.log("API Response:", result.data);

      if (response.ok) {
        if (result.data && result.data.length > 0) {
          setSettledHistory(result.data);
        } else {
          setSettledHistory([]);
        }
      } else {
        console.error("Error fetching settlement data:", result.message);
        setSettledHistory([]);
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
      setSettledHistory([]);
    }
  };
  //handel filter change for date in pending amount
  const handleDateFilterChange = async (e) => {
    try {
      const date = e;
      const formattedDate = date.split("-").reverse().join("-");
      const response = await fetch(`${BASE_URL_API}agentdata/paymenthistory/${selectedAgent}?date=${formattedDate}`);
      const result = await response.json();

      console.log("The data is:", result.data);

      if (response.status === 200 && result.data) {
        setPendingAmountHistory(result.data);
      } else {
        setPendingAmountHistory([]); // Ensure state is reset when no data is found
      }


    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  }

  //month filter change for advance
  const handleAdvanceMonthFilterChange = async (month) => {
    try {
      const response = await fetch(`${BASE_URL_API}agentdata/getadvancehistory/${selectedAgent}?month=${month}`);
      const result = await response.json();

      console.log("API Response:", result.data);

      if (response.ok) {
        if (result.data && result.data.length > 0) {
          setAdvanceHistory(result.data);
        } else {
          setAdvanceHistory([]);
        }
      } else {
        console.error("Error fetching settlement data:", result.message);
        setAdvanceHistory([]);
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  }
  //date filter change for advance
  const handleAdvanceDateFilterChange = async (e) => {
    try {
      const date = e;
      const formattedDate = date.split("-").reverse().join("-");

      const response = await fetch(`${BASE_URL_API}agentdata/getadvancehistory/${selectedAgent}?date=${formattedDate}`);
      const result = await response.json();

      console.log("API Response:", result.data);

      if (response.ok) {
        if (result.data && result.data.length > 0) {
          setAdvanceHistory(result.data);
        } else {
          setAdvanceHistory([]);
        }
      } else {
        console.error("Error fetching settlement data:", result.message);
        setAdvanceHistory([]);
      }

    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  }
  //for checkbox change
  const handleCheckboxChange = async (e, id) => {
    if (e.target.checked) {
      // Add the id if checkbox is checked
      setSelectedTransactionIds((prev) => [...prev, id]);
      setSingleSettle(true);

    } else {
      setSingleSettle(false);
      // Remove the id if checkbox is unchecked
      setSelectedTransactionIds((prev) =>
        prev.filter((selectedId) => selectedId !== id)
      );
    }
  };

  console.log("the selected id is", selectedTransactionIds);


  const sendTransactionData = async () => {
    try {
      const requestData = {
        paymentIds: selectedTransactionIds, // Send selected transaction IDs
      };

      const response = await fetch(`${BASE_URL_API}agentdata/calculateamount/${selectedAgent}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      console.log("Response data:", data);
      Setsinglesettle_amnt(data.totalAmount);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  //function for sent otp to the admin api
  const sendOtp = async () => {
    try {
      // const response = await fetch(`${BASE_URL_API}agentdata/sendotpadmin`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   }
      //   });
      const response = await axios.post(`${BASE_URL_API}agentdata/sendotpadmin`);
      console.log("the response coming from the send otp api is", response);
      if (response.status == 200) {
        alert("OTP sent successfully");
        setShowOtpModal(true);
        setView(false);
        Set_single_settle_model(false);
        setAdvanceView(false);
        setsecond_modal(false);

      }

    } catch (error) {
      console.log(error);
    }
  }
  //function for single settlement payment in recycle pay
  const singlePayment = async (req, res) => {
    try {

      const requestData = {
        mode: settlementMode,
        date: settlementDate,
        paymentIds: selectedTransactionIds,
        selectedOption: selectedOptions,
        note: singleSettleNote,
        deductReason: deductNoteforSinglestlmnt,
        deductAmount: deductAmount_single


      }
      const response = await fetch(`${BASE_URL_API}agentdata/singlesettlement/${selectedAgent}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),

      });
      if (response.status == 200) {
        Swal.fire({
          title: "Payment Successfull",
          text: "Payment Successfull",
          icon: "success",
        })
        fetchData();
        setPending(false);
        setSettled(true);
        setAdvanceNote('');


        setTimeout(() => {
          setHighlightFirstRow(true);
        }, 500);
      }



      else {
        Swal.fire({
          title: "Payment Failed",
          text: `Payment Failed`,
          icon: "error",
        })
        // setShowOtpModal(false);
        // setSingleSettle(false);
        // fetchData()
        // setSettlementDate('');
        // setSettlementMode('');
        // setSelectedUpi("");
        // setBankAccounts([]);
      }


    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      }
      )
      setShowOtpModal(false);
      setSingleSettle(false);
      fetchData()
      setSettlementDate('');
      setSettlementMode('');
      setSelectedUpi("");
      setBankAccounts([]);
      setAdvanceNote('');
    }
    finally {
      setSelectedTransactionIds([])
      setShowOtpModal(false);
      setSingleSettle(false);
      fetchData()
      setSettlementDate('');
      setSettlementMode('');
      setSelectedUpi("");
      setBankAccounts([]);
      setOtp('')
      setAdvanceNote('');
      setDeductAmount_single('');
      setDeductNoteforSinglestlmnt('');
    }
  }
  //function for all settlement payment in recycle pay
  const payment = async () => {
    try {
      const requestData = {
        mode: settlementMode,
        date: settlementDate,
        note: fullsettlenote,
        deductAmount: deductAmount,
        deductReason: deductionNote
      }
      const response = await fetch(`${BASE_URL_API}agentdata/settleallamnt/${selectedAgent}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),

      });
      if (response.status == 200) {
        Swal.fire({
          title: "Payment Successfull",
          text: "Payment Successfull",
          icon: "success",
        })

        // setShowOtpModal(false);
        // fetchData();
        // setSettlementDate('');
        // setSettlementMode('');
        // setSelectedUpi("");
        // setBankAccounts([]);

      }
      else {
        Swal.fire({
          title: "Payment Failed",
          text: `${response.message}`,
          icon: "error",
        })
        // setShowOtpModal(false);
        // setSettlementDate('');
        // setSettlementMode('');
        // setSelectedUpi("");
        // setBankAccounts([]);
        // fetchData();
      }

    } catch (error) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      }
      )
    }
    finally {
      setShowOtpModal(false);
      fetchData();
      setSettlementDate('');
      setSettlementMode('');
      setSelectedUpi("");
      setBankAccounts([]);
      SetfullSettle(false);
      setfullsettleNote('');
      setDeductAmount('');
      setDeductionNote('');
    }
  }
  //function for verify the otp
  const verifyOtp = async () => {
    try {
      const requestData = {
        otp: otp,
      }
      const response = await fetch(`${BASE_URL_API}agentdata/verifyotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      console.log("the response coming from the verifyotp is", response);
      if (response.status == 200) {
        alert('otp verified')
        if (checkAdvance == true) {
          await handleSubmitForAdvance();
        }
        if (selectedTransactionIds.length > 0) {
          await singlePayment();
        }
        if (fullsettle == true) {
          await payment()
        }

      }
      else {
        alert('invalid otp')
      }

    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmitForAdvance = async () => {
    const requestData = {
      advance: amount, // Ensure `amount` state is set correctly
      mode_of_advance: settlementMode, // Value from dropdown
      date: settlementDate, // Ensure date is formatted properly
      note: advanceNote
    };



    try {
      const response = await fetch(`${BASE_URL_API}agentdata/addadvance/${selectedAgent}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();
      console.log("Response from server:", result);
      if (response.status == 200) {
        setAdvance(parseInt(Advance, 10) + parseInt(amount, 10));
        Swal.fire({
          title: 'Advance Added Successfully',
          icon: 'success',

        })
        // navigate(-1);
        setAdvanceView(false);
        setShowOtpModal(false);
        fetchData();
        setAdvanceNote('');

      }
      else {
        Swal.fire({
          title: 'Failed to Add Advance',
          icon: 'error',
          text: `something went wrong`

        })
        setAdvanceView(false);
        setShowOtpModal(false);
        fetchData()
        setAdvanceNote('');
      }
    } catch (error) {
      console.error("Error submitting advance data:", error);
    }
    finally {
      SetAdvance(false);
    }

  };
  const fetchAgent = () => {
    axios.get(BASE_URL_API + 'users?utype=agent')
      .then(res => {
        setAgent(res.data);
        setSelectedAgent(res.data[0]._id);

        // if(settledHistory.length>0){
        //   setSettled(true);
        // }
        // if(advanceHistory.length>0){
        //   setAdvance(true);
        // }
      })
      .catch(err => console.log(err));
  }
  const fetchData = () => {


    //fetch the dynamic data what has been settled what to settle and what is the advance
    axios.get(`${BASE_URL_API}agentdata/totalwages/${selectedAgent}`)
      .then(res => {

        // setSettledAmount(res.data.totalsettled_amount);
        setPendingAmount(res.data.pending_amount);
        setAdvance(res.data.advance);
      })
      .catch(err => console.log(err));
    axios.get(`${BASE_URL_API}agentdata/getsettlementdata/${selectedAgent}`)
      .then(res => {
        if (res.data && res.data.data.length > 0) {
          console.log("The response is", res.data);

          setSettledAmount(res.data.data[0].amount);
          console.log("The amount is", res.data.data[0].amount);

          setLastSettledDate(res.data.data[0].settlement_date);
          console.log("The date is", res.data.data[0].settlement_date);

          setSettledHistory(res.data.data);
          console.log("The settlement history is", res.data.data);
          // if(settledHistory.length>0 && pendingamountHistory.length==0 && advanceHistory.length==0){
          //   setSettled(true);
          // }

        } else {
          setSettledAmount(0);
          setLastSettledDate("N/A");
          setSettledHistory([]);
        }

        //   setSettledHistory(res.data.lastSettlement);



      })
      .catch(err => console.log(err));
    //fetch the advance data history
    axios.get(`${BASE_URL_API}agentdata/getadvancehistory/${selectedAgent}`)
      .then(res => {

        setAdvanceHistory(res.data.data);
        // if(advanceHistory.length>0){
        //   setadvance(true);
        // }
        // if(pendingamountHistory.length==0 && settledHistory.length==0){
        //     setadvance(true);
        // }

      })
      .catch(err => console.log(err));
    //fetch the pending amount history
    axios.get(`${BASE_URL_API}agentdata/paymenthistory/${selectedAgent}`)
      .then(res => {

        setPendingAmountHistory(res.data.data);
        if (pendingamountHistory.length > 0) {
          setPending(true);
          setSettled(false); // Ensures only Pending table is shown
          console.log("Pending condition satisfied");
        } else if (settledHistory.length > 0) {
          setSettled(true);
          setPending(false); // Ensures Pending table is hidden if Settled is shown
          console.log("Settled condition satisfied");
        }

        // if(pendingamountHistory.length==0 && advanceHistory.length>0){
        //   setAdvance(true);
        //  }
        // if(settledHistory.length==0 && pendingamountHistory.length>0 && advanceHistory.length==0){
        //   setPending(true);
        // }




      })
      .catch(err => console.log(err));
    //fetch the account details of the agent by id
    axios.get(`${BASE_URL_API}agentdata/getagentaccount-details/${selectedAgent}`)
      .then(res => {
        if (res.data && res.data.data) {
          const upiAddress = res.data.data.upi?.address || "";
          const bankData = res.data.data.bank || [];

          setUpiDetails(upiAddress);
          setBankAccounts(bankData);
        } else {
          console.log("called Error functions");
          setUpiDetails("");
          setBankAccounts([]);
        }
      })
      .catch(err => {
        console.error(err);
        setUpiDetails("");
        setBankAccounts([]);
      });






  };

  useEffect(() => {
    fetchAgent();
  }, []);
  useEffect(() => {
    fetchData();
    if (singlesettle_amnt > Advance) {
      setSelectedOptions({ advance: true, payment: true });
    }


  }, [selectedAgent, Advance, singlesettle_amnt]);
  console.log("the bank account details is", bank_account_number, ifsc_number, Name);

  //function to navigate to the agent tracking page
  const navigatee = () => {
    navigate(`/dashboard/trackagent/${selectedAgent}/${date}`)
  }

  return (
    <div className="card">
      <h5 className="card-header">Agent Settlement</h5>
      <div className='card-body'>
        <div className="d-flex justify-content-between">
          <div class="d-grid gap-2 d-md-flex justify-content-md-end p-0">
            <select value={selectedAgent} onChange={(e) => setSelectedAgent(e.target.value)} className="form-select form-select-md rounded " style={{ borderColor: "#73be44" }}>
              <option value="">Select Agent</option>
              {
                agent.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}

            </select>
            {/* <select
  value={selectedAgent}
  onChange={(e) => {
    console.log("Selected Agent:", e.target.value);
    setSelectedAgent(e.target.value);
  }}
  className="form-select form-select-md rounded"
  style={{ borderColor: "#73be44" }}
>
  {agent.length === 0 ? (
    <option>Loading...</option>
  ) : (
    agent.map((item) => (
      <option key={item._id} value={item._id}>
        {item.name}
      </option>
    ))
  )}
</select> */}
          </div>
        </div>
        <div className="col-12 d-flex mt-4">
          <div className="col-5 d-flex">
            <div className="card flex-fill radius-10 border-start border-0 border-3 border-info p-3">
              <div className="card-body text-center">
                <h5 className="text-bold mb-4">Last Settled</h5>
                <h4 className="mb-4 text-info">Amount: ₹{settledAmount ?? 0}</h4>

                {lastSettledDate && lastSettledDate !== "N/A" && (
                  <p className="mb-3 text-muted">Date: {lastSettledDate}</p>
                )}


                {settledHistory && settledHistory?.length > 0 && (
                  <p
                    className="text-primary"
                    onClick={() => {
                      setSettled((prevState) => !prevState);
                      setPending(false);
                      setadvance(false);
                    }}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Show More
                  </p>
                )}

              </div>
            </div>
          </div>
          <div className="col-7 d-flex">
            <div className="card flex-fill radius-10 border-start border-0 border-3 border-success p-3">
              <div className="card-body">
                <h5 className="text-center mb-4" style={{ color: "#000" }}>
                  <b>Accumulated Payment</b>
                </h5>
                <div className="d-flex justify-content-between">
                  <div className="p-3 d-flex justify-content-between align-items-center" style={{ flex: "1", marginRight: "15px", borderRight: "1px solid #ddd" }}>
                    <div>
                      <h5 className="mb-1" style={{ color: "#73be44" }}>
                        <b>To Pay</b>
                      </h5>
                      <h4 className="my-1 text-danger">₹{pendingAmount}</h4>
                      {pendingamountHistory?.length > 0 && (
                        <p
                          className="text-primary"
                          onClick={() => {
                            setPending((prevState) => !prevState);
                            setSettled(false);
                            setadvance(false);
                          }}
                          style={{ cursor: "pointer", textDecoration: "underline" }}
                        >
                          Show More
                        </p>
                      )}
                      {/* disabled={pendingAmount < Advance || pendingAmount <= 0} */}
                    </div>
                    <div>
                      <Button className="btn" style={{ background: "#73be44", color: "#fff" }} disabled={pendingAmount < Advance || pendingAmount <= 0 || singleSettle == true} onClick={() => { setView(true) }}>
                        <i className="secondary"></i> Settle
                      </Button>
                    </div>
                  </div>
                  <div className="p-3 d-flex justify-content-between align-items-center" style={{ flex: "1", marginLeft: "15px" }}>
                    <div>
                      <p className="mb-1" style={{ color: "#73be44" }}>
                        <b>Advance Given</b>
                      </p>
                      <h4 className="my-1 text-success">₹{Advance}</h4>
                      {advanceHistory && advanceHistory.length > 0 && (
                        <p
                          className="mb-0 text-primary"
                          onClick={() => {
                            setadvance((prevState) => !prevState);
                            setSettled(false);
                            setPending(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Show More
                        </p>
                      )}
                    </div>

                    {/* Button Positioned to the Right */}
                    <Button
                      className="btn"
                      style={{ background: "#73be44", color: "#fff", marginLeft: "10px" }}
                      disabled={singleSettle == true}
                      onClick={() => {
                        setAdvanceView(true);
                      }}
                    >
                      <i className="secondary"></i> Give Advance
                    </Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>




      {settled && (

        <div className="mx-6 p-5">
          <div class="d-grid gap-2 d-md-flex justify-content-md-start p-2 ">
            <select className="form-select form-select-sm " style={{ width: '150px', height: '40px', borderColor: "#73be44" }}
              onChange={(e) => handleMonthChange(e.target.value)}
            >
              <option value="">All Months</option>
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((month, index) => (
                <option key={index} value={(index + 1).toString().padStart(2, "0")}>
                  {month}
                </option>
              ))}

            </select>
          </div>
          <div
            className="table-container"
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >

            <table
              className="table mb-0"
              style={{
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
              }}
            >

              {/* Table Header with Filters */}
              <thead
                style={{
                  backgroundColor: "#E6F8DB",
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
              >
                <tr>
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#E6F8DB",
                      zIndex: 10,
                      padding: "10px",
                      width: "180px",
                      textAlign: "left",
                      paddingLeft: "20px",
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>Date</span>
                      <input
                        type="date"
                        value={filterDate}
                        onChange={handleFilterChange}
                        style={{
                          padding: "4px",
                          fontSize: "14px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          width: "100%",
                          cursor: "pointer",
                          backgroundColor: "#fff",
                        }}
                      />
                    </div>
                  </th>


                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#E6F8DB",
                      zIndex: 10,
                      padding: "10px",
                      width: "200px",
                      textAlign: "center",
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    Settled Amount
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#E6F8DB",
                      zIndex: 10,
                      padding: "10px",
                      width: "200px",
                      textAlign: "center",
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    Transaction Id
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      background: "#E6F8DB",
                      zIndex: 10,
                      padding: "10px",
                      width: "200px",
                      textAlign: "center",
                      borderBottom: "2px solid #ccc",
                    }}
                  >
                    Name
                  </th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody style={{ fontSize: "14px" }}>
                {settledHistory?.length > 0 ? (
                  settledHistory.map((data, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index === 0 ? "#ffff99" : "transparent", // Highlight first row
                        fontWeight: index === 0 ? "bold" : "normal", // Make text bold for first row
                      }}
                    >
                      <td
                        style={{
                          padding: "10px",
                          paddingLeft: "20px",
                          borderBottom: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        {data.settlement_date},{" "}
                        {data._id
                          ? new Date(data._id * 1000).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                          : "N/A"}

                        {/* {data._id
  ? new Date(parseInt(data._id.toString().substring(0, 8), 16) * 1000).toLocaleTimeString("en-US", {
      timeZone: "Asia/Kolkata", // Ensure conversion to IST
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  : "N/A"} */}

                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #ddd",
                          textAlign: "center",
                          fontWeight: data.amount === 0 ? "bold" : "normal",
                          color: data.amount === 0 ? "green" : "black",
                        }}
                      >
                        {data.amount === null ? "Advance Settled" : `₹${data.amount}`}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        {data.p_outId}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #ddd",
                          textAlign: "center",
                        }}
                      >
                        {data.name}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#555",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>

            </table>
          </div>
        </div>
      )}




      {pending && (
        <div className="mx-6 p-5" style={{ position: "relative" }}>
          {/* Settle Button */}
          {selectedTransactionIds.length > 0 && (
            <button
              onClick={() => {
                Set_single_settle_model(true);
                sendTransactionData();
              }}
              style={{
                backgroundColor: "#73BE44",
                color: "#fff",
                padding: "10px 20px",
                fontSize: "14px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                position: "absolute",
                right: "90px",
                top: "50px",
                transition: "0.3s ease",
              }}
            >
              Settle
            </button>
          )}

          {/* Month Filter Dropdown */}
          <select
            className="form-select form-select-sm"
            style={{
              width: "170px",
              height: "40px",
              border: "1px solid #73BE44",
              fontSize: "14px",
              fontWeight: "bold",
              padding: "5px",
            }}
            onChange={(e) => handleMonthFilterChange(e.target.value)}
          >
            <option value="">All Months</option>
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((month, index) => (
              <option key={index} value={(index + 1).toString().padStart(2, "0")}>
                {month}
              </option>
            ))}
          </select>

          {/* Table Container */}
          {pendingamountHistory?.length > 0 ? (
            <div
              className="table-container"
              style={{
                maxHeight: "450px",
                overflowY: "auto",
                border: "1px solid #ccc",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                marginTop: "10px",
                minHeight: "200px",
              }}
            >
              <table
                className="table mb-0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  tableLayout: "fixed",
                }}
              >
                <thead
                  style={{
                    backgroundColor: "#E6F8DB",
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                  }}
                >
                  <tr>
                    <th style={{ width: "33.33%", textAlign: "center", padding: "12px" }}>
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={
                          selectedTransactionIds.length === pendingamountHistory?.length
                        }
                        style={{ marginRight: "5px" }}
                      />
                      <label>Select All</label>
                    </th>
                    <th style={{ width: "33.33%", textAlign: "center", padding: "12px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        Date
                        <input
                          type="date"
                          onChange={(e) => handleDateFilterChange(e.target.value)}
                          style={{
                            width: "150px",
                            marginTop: "5px",
                            padding: "5px",
                            fontSize: "12px",
                            border: "1px solid #73BE44",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                        />
                      </div>
                    </th>
                    <th style={{ width: "33.33%", textAlign: "center", padding: "12px" }}>
                      Daily Wages
                    </th>

                    <th style={{ width: "33.33%", textAlign: "center", padding: "12px" }}>
                      Track Agent
                    </th>
                  </tr>
                </thead>

                <tbody style={{ fontSize: "14px", backgroundColor: "#fff" }}>
                  {pendingamountHistory?.map((data, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                      <td style={{ padding: "12px", textAlign: "center" }}>
                        <input
                          type="checkbox"
                          onChange={(e) => handleCheckboxChange(e, data._id)}
                          checked={selectedTransactionIds.includes(data._id)}
                        />
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {data.Date}
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                          color: "#28a745",
                        }}
                      >
                        ₹{data.Daily_wages}
                      </td>
                      <td
                        style={{
                          padding: "12px",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <Button
                          onClick={() => navigate(`/dashboard/trackagent/${selectedAgent}/${data.Date}`)}
                          style={{
                            backgroundColor: "#73BE44",
                            color: "#fff",
                            padding: "5px 10px",
                            fontSize: "12px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            marginLeft: "100px"
                          }}
                        >
                          <i className="fa-solid fa-location-arrow"></i> Track
                        </Button>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontWeight: "bold",
                color: "#555",
              }}
            >
              No pending amount history found.
            </p>
          )}

        </div>
      )}







      {
        advance && (
          <div className="mx-6 p-5">
            {/* Month Filter Dropdown */}
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <select
                className="form-select form-select-sm"
                style={{
                  width: "170px",
                  height: "40px",
                  border: "1px solid #73BE44",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px",
                }}
                onChange={(e) => handleAdvanceMonthFilterChange(e.target.value)}
              >
                <option value="">All Months</option>
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <option key={index} value={(index + 1).toString().padStart(2, "0")}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {/* Table Wrapper */}
            <div
              className="table-responsive table-bordered"
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <table className="table mb-0" style={{ width: "100%" }}>
                {/* Table Header */}
                <thead
                  style={{
                    backgroundColor: "#E6F8DB",
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                  }}
                >
                  <tr>
                    <th style={{ width: "30%", textAlign: "center", padding: "12px" }}>
                      Date
                      <input
                        type="date"
                        onChange={(e) => handleAdvanceDateFilterChange(e.target.value)}
                        style={{
                          display: "block",
                          width: "40%",
                          margin: "5px auto 0",
                          padding: "5px",
                          fontSize: "12px",
                          border: "1px solid #73BE44",
                          borderRadius: "5px",
                        }}
                      />
                    </th>
                    <th style={{ width: "25%", textAlign: "center", padding: "12px" }}>
                      Advance Given
                    </th>
                    <th style={{ width: "35%", textAlign: "center", padding: "12px" }}>
                      Transaction Id
                    </th>
                  </tr>
                </thead>

                {/* Table Body */}
                <tbody style={{ fontSize: "14px", textAlign: "center" }}>
                  {advanceHistory.length > 0 ? (
                    advanceHistory.map((data, index) => (
                      <tr key={index}>
                        <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                          {data.Date},{data.paymentdetails[0].payment_date
                            ? new Date(data.paymentdetails[0].payment_date * 1000).toLocaleTimeString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true
                            })
                            : "N/A"}
                        </td>
                        <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                          ₹{data.advance}
                        </td>
                        <td style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>{data.paymentdetails[0].p_outId}</td>

                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ padding: "20px", textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )
      }


      {/* modal for single settlement */}
      <Modal
        size="md"
        show={single_settle_model}
        onHide={() => Set_single_settle_model(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton className="d-flex flex-column align-items-center">
          <Modal.Title id="example-modal-sizes-title-md">Agent Settlement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "110px",
                }}
              >
                Selected Total - {singlesettle_amnt}
              </Form.Label>
              <Row className="my-3 align-items-center">
                <Col xs={6} className="text-end">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>Deduction Amount -</span>
                </Col>
                <Col xs={6}>
                  <input
                    type="text"
                    value={deductAmount_single}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setDeductAmount_single(value);
                      fetchGrantTotalForSingleSettlement(value);
                    }}
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      width: "100px",
                      textAlign: "left", // Align text to the left
                    }}
                  />
                </Col>
                {/* Conditionally render hr if deductAmount has a value */}
                {deductAmount_single && (
                  <>
                    <hr style={{ width: "80%", margin: "10px auto", borderTop: "2px solid #000" }} />

                    <Row className="my-3 align-items-center">
                      <Col xs={6} className="text-end">
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Grant Total -</span>
                      </Col>
                      <Col xs={6}>
                        <span style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "11px" }}>₹ {grantTotalforSingleSettlement}</span>
                      </Col>
                    </Row>
                    <Form.Group className="mt-3">
                      <Form.Label style={{ fontWeight: "bold" }}>Add Reason for Deduction</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter the reason for Deduction"
                        value={deductNoteforSinglestlmnt}
                        onChange={(e) => setDeductNoteforSinglestlmnt(e.target.value)}

                      />
                    </Form.Group>
                  </>
                )}

              </Row>

              <Form.Label style={{ marginTop: "5px" }}>Choose settlement Mode</Form.Label>
              <div style={{ marginTop: "2px" }}>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Cash"
                    name="settlementMode"
                    value="Cash"
                    checked={settlementMode === "Cash"}
                    onChange={(e) => setSettlementMode(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="UPI"
                    name="settlementMode"
                    value="UPI"
                    checked={settlementMode === "UPI"}
                    onChange={(e) => {
                      setSettlementMode(e.target.value);
                      setSelectedUpi("");
                    }}
                  />
                  {settlementMode === "UPI" && upiDetails && (
                    <Form.Group className="mt-2">
                      <Form.Label style={{ fontWeight: "bold" }}>UPI ID</Form.Label>
                      <Form.Control type="text" value={upiDetails} readOnly />
                    </Form.Group>
                  )}
                  <Form.Check
                    type="radio"
                    label="Bank Transfer"
                    name="settlementMode"
                    value="IMPS"
                    checked={settlementMode === "IMPS"}
                    onChange={(e) => setSettlementMode(e.target.value)}
                  />
                  {settlementMode === "IMPS" && bankAccounts && (
                    <div className="mt-2">
                      <Form.Group>
                        <Form.Label style={{ fontWeight: "bold" }}>Account Holder Name</Form.Label>
                        <Form.Control type="text" value={bankAccounts.name} readOnly />
                      </Form.Group>
                      <Form.Group className="mt-2">
                        <Form.Label style={{ fontWeight: "bold" }}>IFSC Code</Form.Label>
                        <Form.Control type="text" value={bankAccounts.ifsc} readOnly />
                      </Form.Group>
                      <Form.Group className="mt-2">
                        <Form.Label style={{ fontWeight: "bold" }}>Account Number</Form.Label>
                        <Form.Control type="text" value={bankAccounts.account_number} readOnly />
                      </Form.Group>
                    </div>
                  )}
                </Form.Group>
              </div>

              {/* Add Note Field */}
              <Form.Group className="mt-3">
                <Form.Label style={{ fontWeight: "bold" }}>Add Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter any additional notes"
                  value={singleSettleNote}
                  onChange={(e) => setSinglesettleNote(e.target.value)}
                  required
                />
              </Form.Group>

              <div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
                <Button
                  className="btn btn-primary"
                  onClick={() => {
                    setsecond_modal(true);
                    Set_single_settle_model(false);
                  }}
                >
                  Next
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>


      {/* modal for the settlement  */}
      <Modal
        size="md"
        show={view}
        onHide={() => setView(false)}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton className="d-flex flex-column align-items-center">
          <Modal.Title id="example-modal-sizes-title-md">Agent Settlement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* Upper Section with Payment Details */}
              <Row className="my-3 align-items-center">
                <Col xs={6} className="text-end">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>To pay -</span>
                </Col>
                <Col xs={6}>
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>₹{pendingAmount}</span>
                </Col>
              </Row>
              <Row className="my-3 align-items-center">
                <Col xs={6} className="text-end">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>Advance -</span>
                </Col>
                <Col xs={6}>
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>₹{Advance}</span>
                </Col>
              </Row>
              <hr style={{ width: "80%", margin: "10px auto", borderTop: "2px solid #000" }} />
              <Row className="my-3 align-items-center">
                <Col xs={6} className="text-end">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>Total -</span>
                </Col>
                <Col xs={6}>
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>₹{total}</span>
                </Col>
              </Row>
              <Row className="my-3 align-items-center">
                <Col xs={6} className="text-end">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>Deduction Amount -</span>
                </Col>
                <Col xs={6}>
                  <input
                    type="text"
                    value={deductAmount}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setDeductAmount(value);
                      fetchGrantTotal(value);
                    }}
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      width: "100px",
                      textAlign: "left", // Align text to the left
                    }}
                  />
                </Col>
                {/* Conditionally render hr if deductAmount has a value */}
                {deductAmount && (
                  <>
                    <hr style={{ width: "80%", margin: "10px auto", borderTop: "2px solid #000" }} />

                    <Row className="my-3 align-items-center">
                      <Col xs={6} className="text-end">
                        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Grant Total -</span>
                      </Col>
                      <Col xs={6}>
                        <span style={{ fontSize: "18px", fontWeight: "bold", marginLeft: "11px" }}>₹ {grantTotalforFullSettlement}</span>
                      </Col>
                    </Row>
                    <Form.Group className="mt-3">
                      <Form.Label style={{ fontWeight: "bold" }}>Add Reason for Deduction</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter the reason for Deduction"
                        value={deductionNote}
                        onChange={(e) => setDeductionNote(e.target.value)}

                      />
                    </Form.Group>
                  </>
                )}

              </Row>


              {/* Settlement Mode Options */}
              <Form.Label>Choose Settlement Mode</Form.Label>
              <div style={{ marginTop: "2px" }}>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Cash"
                    name="settlementMode"
                    value="Cash"
                    checked={settlementMode === "Cash"}
                    onChange={(e) => setSettlementMode(e.target.value)}
                  />

                  <Form.Check
                    type="radio"
                    label="UPI"
                    name="settlementMode"
                    value="UPI"
                    checked={settlementMode === "UPI"}
                    onChange={(e) => {
                      setSettlementMode(e.target.value);
                      setSelectedUpi(""); // Reset UPI selection if needed
                    }}
                  />

                  {/* UPI Details */}
                  {settlementMode === "UPI" && (
                    <>
                      {upiDetails ? (
                        <Form.Group className="mt-2">
                          <Form.Label style={{ fontWeight: "bold" }}>UPI ID</Form.Label>
                          <Form.Control type="text" value={upiDetails} readOnly />
                        </Form.Group>
                      ) : (
                        <Form.Text className="text-muted">No UPI details found</Form.Text>
                      )}
                    </>
                  )}

                  <Form.Check
                    type="radio"
                    label="Bank Transfer"
                    name="settlementMode"
                    value="IMPS"
                    checked={settlementMode === "IMPS"}
                    onChange={(e) => {
                      setSettlementMode(e.target.value);
                    }}
                  />

                  {/* Bank Details */}
                  {settlementMode === "IMPS" && (
                    <>
                      {bankAccounts ? (
                        <div className="mt-2">
                          <Form.Group>
                            <Form.Label style={{ fontWeight: "bold" }}>Account Holder Name</Form.Label>
                            <Form.Control type="text" value={bankAccounts.name} readOnly />
                          </Form.Group>
                          <Form.Group className="mt-2">
                            <Form.Label style={{ fontWeight: "bold" }}>IFSC Code</Form.Label>
                            <Form.Control type="text" value={bankAccounts.ifsc} readOnly />
                          </Form.Group>
                          <Form.Group className="mt-2">
                            <Form.Label style={{ fontWeight: "bold" }}>Account Number</Form.Label>
                            <Form.Control type="text" value={bankAccounts.account_number} readOnly />
                          </Form.Group>
                        </div>
                      ) : (
                        <Form.Text className="text-muted">No bank account details found</Form.Text>
                      )}
                    </>
                  )}
                </Form.Group>
              </div>
              <Form.Group className="mt-3">
                <Form.Label style={{ fontWeight: "bold" }}>Add Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter any additional notes"
                  value={fullsettlenote}
                  onChange={(e) => setfullsettleNote(e.target.value)}
                  required
                />
              </Form.Group>

              {/* Date Selection */}
              {/* <div style={{ marginTop: "20px" }}>
                <Form.Label className="fw-semibold">Date</Form.Label>
                <Form.Control
                  type="date"
                  value={
                    settlementDate
                      ? `${settlementDate.split("-")[2]}-${settlementDate.split("-")[1]}-${settlementDate.split("-")[0]}`
                      : ""
                  }
                  onChange={handleDateChange}
                  className="p-2"
                  style={{ cursor: "pointer" }}
                />
              </div> */}

              {/* Settle Button */}
              <div className="d-flex justify-content-end" style={{ marginTop: "10px" }}>
                <Button className="btn btn-primary" onClick={() => { SetfullSettle(true); sendOtp() }}>
                  Settle
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>



      {/* modal for advance give */}
      <Modal
        size="md"
        show={advanceView}
        onHide={() => setAdvanceView(false)}
        aria-labelledby="example-modal-sizes-title-md"
        centered
      >
        <Modal.Header closeButton className="d-flex flex-column align-items-center">
          <Modal.Title id="example-modal-sizes-title-md" className="fw-bold">
            Give Advance
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="d-flex flex-column gap-3">
                {/* Select Days Dropdown */}
                <div>
                  <Form.Label className="fw-semibold">Select Days</Form.Label>
                  <Form.Select
                    value={selectedDays}
                    onChange={(e) => handleDaySelection(e.target.value)}
                    className="p-2"
                  >
                    <option value="">Select Days</option>
                    {[1, 2, 3, 4, 5].map((day) => (
                      <option key={day} value={day}>
                        {day} Day{day > 1 ? "s" : ""}
                      </option>
                    ))}
                  </Form.Select>
                </div>

                {/* Amount Field (Read-Only) */}
                <div>
                  <Form.Label className="fw-semibold">Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={amount}
                    readOnly
                    className="p-2 bg-light"
                  />
                </div>

                {/* Mode of Advance (Cash / UPI / Bank Transfer) */}
                <div style={{ marginTop: "2px" }}>
                  <Form.Group>
                    <Form.Check
                      type="radio"
                      label="Cash"
                      name="settlementMode"
                      value="Cash"
                      checked={settlementMode === "Cash"}
                      onChange={(e) => setSettlementMode(e.target.value)}
                    />

                    <Form.Check
                      type="radio"
                      label="UPI"
                      name="settlementMode"
                      value="UPI"
                      checked={settlementMode === "UPI"}
                      onChange={(e) => {
                        setSettlementMode(e.target.value);
                        setSelectedUpi(""); // Reset UPI selection if needed
                      }}
                    />

                    {/* UPI Details */}
                    {settlementMode === "UPI" && (
                      <>
                        {upiDetails ? (
                          <Form.Group className="mt-2">
                            <Form.Label style={{ fontWeight: "bold" }}>UPI ID</Form.Label>
                            <Form.Control type="text" value={upiDetails} readOnly />
                          </Form.Group>
                        ) : (
                          <Form.Text className="text-muted">No UPI details found</Form.Text>
                        )}
                      </>
                    )}

                    <Form.Check
                      type="radio"
                      label="Bank Transfer"
                      name="settlementMode"
                      value="IMPS"
                      checked={settlementMode === "IMPS"}
                      onChange={(e) => {
                        setSettlementMode(e.target.value);
                      }}
                    />

                    {/* Bank Details */}
                    {settlementMode === "IMPS" && (
                      <>
                        {bankAccounts ? (
                          <div className="mt-2">
                            <Form.Group>
                              <Form.Label style={{ fontWeight: "bold" }}>Account Holder Name</Form.Label>
                              <Form.Control type="text" value={bankAccounts.name} readOnly />
                            </Form.Group>
                            <Form.Group className="mt-2">
                              <Form.Label style={{ fontWeight: "bold" }}>IFSC Code</Form.Label>
                              <Form.Control type="text" value={bankAccounts.ifsc} readOnly />
                            </Form.Group>
                            <Form.Group className="mt-2">
                              <Form.Label style={{ fontWeight: "bold" }}>Account Number</Form.Label>
                              <Form.Control type="text" value={bankAccounts.account_number} readOnly />
                            </Form.Group>
                          </div>
                        ) : (
                          <Form.Text className="text-muted">No bank account details found</Form.Text>
                        )}
                      </>
                    )}
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label style={{ fontWeight: "bold" }}>Add Note</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter any additional notes"
                value={advanceNote}
                onChange={(e) => setAdvanceNote(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        {/* Footer with Buttons */}
        <Modal.Footer className="d-flex justify-content-end">
          <Button variant="outline-secondary" className="px-4" onClick={() => setAdvanceView(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="px-4"
            onClick={() => {
              SetAdvance(true);
              sendOtp();
            }}
            disabled={!amount} // Disable if no amount is set
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>



      {showotpverifyModal && (
        <Modal
          size="md"
          show={showotpverifyModal}
          onHide={() => setShowOtpModal(false)}
          aria-labelledby="example-modal-sizes-title-md"
          centered
        >
          {/* Modal Header with Close Button */}
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Verification Code</Modal.Title>
          </Modal.Header>

          {/* Modal Body */}
          <Modal.Body className="p-4 text-center">
            <Form.Group controlId="otpInput" className="mb-3">
              <Form.Control
                type="text"
                placeholder="E.g. 1728"
                maxLength={4}
                value={otp}
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  padding: "10px",
                }}
                onChange={(e) => setOtp(e.target.value.replace(/\D/, ""))} // Optional: ensures only digits
              />
            </Form.Group>

            <Button
              variant="secondary"
              className="w-100 py-2 mb-3"
              style={{ fontSize: "1.1rem" }}
              onClick={verifyOtp}
              disabled={otp.length !== 4}
            >
              Verify
            </Button>

            <p className="text-muted">
              An email with a 4-digit verification code has been sent to
              <span className="fw-bold"> n****@a*****.co.in</span>.
            </p>
          </Modal.Body>
        </Modal>
      )}

      {/* modal for singlesettlement2 */}
      <Modal
        show={secondsetl_modal}
        onHide={() => {
          setsecond_modal(false);
          setSelectedOptions({ advance: false, payment: false }); // Reset selections
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Payment Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Summary Section */}
          <div className="mt-3 p-2 bg-light rounded">
            {/* Advance Used (only shown if advance is selected) */}
            {selectedOptions.advance && (
              <p className="mb-1">
                <strong>Advance Used:</strong> ₹ {Math.min(singlesettle_amnt, Advance)}
              </p>
            )}

            {/* Remaining Payment - Condition based on deductAmount_single */}
            <p className="mb-0">
              <strong>Remaining Payment:</strong> ₹
              {deductAmount_single > 0
                ? Math.max(
                  singlesettle_amnt - deductAmount_single,
                  0
                )
                : Math.max(
                  singlesettle_amnt - (selectedOptions.advance ? Math.min(singlesettle_amnt, Advance) : 0),
                  0
                )}
            </p>

          </div>


          <Form>
            {/* Advance Checkbox - Selects only one when singlesettle_amnt <= Advance */}
            <Form.Check
              type="checkbox"
              label={`Advance (₹${Advance})`}
              name="advance"
              checked={selectedOptions.advance}
              onChange={(e) => {
                if (singlesettle_amnt <= Advance) {
                  setSelectedOptions({
                    advance: e.target.checked,
                    payment: !e.target.checked, // Auto-deselect "Payment" when selecting "Advance"
                  });
                } else {
                  setSelectedOptions({
                    ...selectedOptions,
                    advance: e.target.checked,
                  });
                }
              }}
              disabled={Advance === 0}
            />

            {/* Payment Checkbox - Auto-deselects Advance when singlesettle_amnt <= Advance */}
            <Form.Check
              type="checkbox"
              label="Payment"
              name="payment"
              checked={
                singlesettle_amnt > Advance || selectedOptions.payment
              } // Always checked if settlement > Advance
              onChange={(e) => {
                if (singlesettle_amnt <= Advance) {
                  setSelectedOptions({
                    advance: !e.target.checked, // Auto-deselect "Advance" when selecting "Payment"
                    payment: e.target.checked,
                  });
                }
              }}
              disabled={singlesettle_amnt > Advance} // Always checked when settlement > Advance
            />
          </Form>

          {/* Analytics Section */}
          <div className="mt-3 p-2 bg-light rounded">
            {/* Advance Used */}
            {selectedOptions.advance && (
              <p className="mb-1">
                <strong>Advance Used:</strong> ₹ {Math.min(singlesettle_amnt, Advance)}
              </p>
            )}

            {/* Remaining Payment */}
            <p className="mb-0">
              <strong>Remaining Payment:</strong> ₹
              {deductAmount_single > 0
                ? Math.max(
                  singlesettle_amnt - deductAmount_single,
                  0
                )
                : Math.max(
                  singlesettle_amnt - (selectedOptions.advance ? Math.min(singlesettle_amnt, Advance) : 0),
                  0
                )}
            </p>

          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setsecond_modal(false);
              setSelectedOptions({ advance: false, payment: false }); // Reset selections
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const finalSelection = {
                advance: selectedOptions.advance,
                payment:
                  singlesettle_amnt > Advance || selectedOptions.payment, // Payment is required if settlement > Advance
              };
              console.log("Final Selected Options:", finalSelection);
              sendOtp(finalSelection); // Send the valid selections
            }}
          >
            Settle
          </Button>
        </Modal.Footer>
      </Modal>









    </div>
  )
}